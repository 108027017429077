<script setup>
import { inject } from 'vue';

const props = defineProps({
    tags: Object,
    date: String,
});

const dayjs = inject('dayjs');
</script>

<template>
    <div class="flex flex-col gap-4 pt-8 mt-8 text-base border-t border-white md:flex-row md:justify-between">
        <div class="flex gap-4" v-if="tags">
            <div v-for="tag in tags" :key="tag.id">
                <div class="px-2 py-1 rounded bg-blue-lighter text-blue">
                    {{ tag.name.en }}
                </div>
            </div>
        </div>

        <div>{{ dayjs(date).format('DD-MM-YYYY') }}</div>
    </div>
</template>